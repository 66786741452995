import React, { useState } from 'react';
import { BACKEND_ENDPOINT } from '../App';

async function downloadData() {
  const response = await fetch(BACKEND_ENDPOINT+'/download_data', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      });
      let data = await response.json();
      const jsonData = new Blob([JSON.stringify(data, null, 4)], { type: 'application/json' });
      const jsonURL = URL.createObjectURL(jsonData);
      const link = document.createElement('a');
      link.href = jsonURL;
      link.download = `stakeholder_survey_raw_data.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  }

  // TODO, multipart/form-data
function uploadData(dataFile) {
  fetch(BACKEND_ENDPOINT+'/upload_validated_data', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: dataFile
    })
      .then((response) => response.json())
      .then((upload_status) => {
        if (upload_status == "valid input") {
          document.getElementById("success-message").innerText = "Your data has been uploaded successfully.";
        } else {
          document.getElementById("success-message").innerText = "Upload failed. Invalid file format.";
        }
      })
      /*.then(upload_status => {
          if (upload_status.ok) { 
            console.log(upload_status)
            if (upload_status.PromiseResult == "valid input") {document.getElementById("success-message").innerText = "Your data has been uploaded successfully.";}
              else {document.getElementById("success-message").innerText = "Upload failed. Invalid file format.";}
          } else {
              document.getElementById("success-message").innerText = "Upload failed";
              console.error('Upload failed:', upload_status.statusText);
          }
      })*/
      .catch(error => {
          document.getElementById("success-message").innerText = "Upload failed";
          console.error('Network error:', error);
      });
}

async function checkPassword(password, setAdminAccess) {
  const response = await fetch(BACKEND_ENDPOINT+'/authenticate_admin', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({"password":password})
  });
  let access = await response.json();
  if (access === "granted") {
    setAdminAccess(true)
      document.getElementById("error-message").innerText = "";
  };
  if (access === "denied") {
    setAdminAccess(false)
      document.getElementById("error-message").innerText = "wrong password";
  } 
}

export function AdminPage() {
  //get input from user
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [adminAccess, setAdminAccess] = useState(false);
  const [file, setFile] = useState();
  const [fileUpload, setFileUpload] = useState(false);
  return (
      <div className='container'>
          <div className="col-lg-9 jumbotron" style={{alignSelf: "center"}}>
              <div style={{alignSelf: "center"}}>
                  <br/>
                  <br/>
                  <label htmlFor="pass">Password: </label>
                  <input
                      className='password-inp'
                      id="pass"
                      type={
                          showPassword ? "text" : "password"
                      }
                      value={password}
                      onChange={(e) =>
                          setPassword(e.target.value)
                      }
                  />
                  <button className="check-password-btn" onClick={checkPassword.bind(this, password, setAdminAccess)}
                          value={password}>ok
                  </button>
              </div>

              <br/>

              <div id="error-message"></div>

              <div>
                  <button disabled={!adminAccess} className="download-data-btn" onClick={downloadData}>download raw
                      data
                  </button>
              </div>
              <br/>
              <br/>
              <div><input disabled={!adminAccess} className='uplooad-data-inp' type="file" accept=".js,.json"
                          onChange={(e) => {
                              setFile(e.target.files[0])
                          }}/></div>
              <div>
                  <button disabled={!adminAccess} className="download-data-btn"
                          onClick={uploadData.bind(this, file)}>upload validated data
                  </button>
              </div>

              <div id="success-message"></div>
          </div>
      </div>
  );
}
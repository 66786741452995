import React, {useEffect, useState} from "react";
import { useMediaQuery } from 'react-responsive'
import Dropdown from '../components/Dropdown'
import NodeLinkGraph from '../components/NodeLinkGraphNEW';
import InfoBox from '../components/InfoBox';
import FilterLegend from '../components/FilterLegend';
import NetworkAnalysis from '../components/NetworkAnalysis';
import { useTranslation } from 'react-i18next';
import {BACKEND_ENDPOINT} from "../App";


//TODO: get data from API
/*
import sD from '../data/data'
const sampleData = sD.map(item => {
    return {

        ...item,
        name: item.name.replace(/\s+/g, ''),
        nodes: item.nodes.map(node => ({
            ...node,
            id: node.id.toString()
        }))
    };
});*/

/*

const data = inputData.map(item => {
    return {
        ...item,
        nodes: item.nodes.map(node => ({
            ...node,
            id: node.id.toString()
        }))
    };
});*/

export function StakeholderMap() {



    /*
        const defaultdata = data.find(entry => entry.id === 1);

        const {
            selectedNodes, setSelectedNodes, filteredNodes,
            selectedEdges, setSelectedEdges,
            selectedSectors, setSelectedSectors,
            selectedNode, setSelectedNode,
            selectedColoringAttrib, setSelectedColoringAttrib} = useNetworkContext();
        */


    const sectorCategories = ['academia', 'civicSociety', 'publicSector', 'privateSector', 'media', 'nonOrganizedCivilSociety'];

    const { t } = useTranslation();

    const [selectedNodes, setSelectedNodes] = useState();
    const [selectedSectors, setSelectedSectors] = useState(sectorCategories);
    const [selectedEdges, setSelectedEdges] = useState();
    const [selectedNode, setSelectedNode] = useState(null);
    const [selectedEdge, setSelectedEdge] = useState(null);
    const [currentHeading, setHeading] = useState(t("pleaseSelect"));
    const [selectedCaseStudy, setSelectedCaseStudy] = useState(null);
    const [coloringAttrib, setColoringAttrib] = useState("sector");

    //TODO: UNCOMMENT
    const [currentData, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const isDesktop = useMediaQuery({
        query: '(min-width: 768px)'
    })

    console.log(selectedCaseStudy)

    const sectorColors = {
        'civicSociety': '#43863E',
        'academia': '#5177A7',
        'privateSector': '#9E3C76',
        'publicSector': '#DD6D78',
        'media': '#81CAEA',
        'nonOrganizedCivilSociety': '#C9BC58',
    };

    /*
    const sectorCategories = [
        {id: 1, name:'academia', isSelected:true},
        {id: 2, name:'civilSociety', isSelected:true},
        {id: 3, name: 'publicSector', isSelected:true},
        {id: 4, name:'Private Sector', isSelected:true},
        {id: 5, name:'Media', isSelected:true},
        {id: 6, name:'nonOrg', isSelected:true}]*/

    /*
    function toggleCategories (id) {
        setSelectedCategories(prevCategories => {
            return prevCategories.map((category) => {
                return category.id === id ? {...category,  isSelected : !category.isSelected} : category
                })
            })
    }*/






    /*
    const toggleCategory = (category) => {
        setSelectedSectors(prev => {
            console.log(prev)
            const newCategories = new Set(prev);
            if (newCategories.has(category)) {
                newCategories.delete(category); // Hide category
            } else {
                newCategories.add(category); // Show category
            }
            return newCategories;
        });
    };*/

    const toggleCategory = (category) => {
        setSelectedSectors((prevSelected) => {
            if (prevSelected.includes(category)) {
                return prevSelected.filter((item) => item !== category);
            } else {
                return [...prevSelected, category];
            }
        });
    };

   useEffect(()=>{

       const id = parseInt(selectedCaseStudy)
       console.log("Id of selected Case Study", id)
       if(currentData){
           const searchData = currentData.find(entry => entry.id === id);

           if (searchData) {
               setSelectedNodes(searchData.nodes)
               setSelectedEdges(searchData.edges)
               setSelectedSectors([])

           }
       }else {
           console.log("No data available")

       }
    }, [selectedCaseStudy]);


    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await fetch(BACKEND_ENDPOINT+'/get_validated_data', {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                console.log("API Response", result);
                const preparedData = result.map(item => {
                    return {

                        ...item,
                        name: item.name.replace(/\s+/g, ''),
                        nodes: item.nodes.map(node => ({
                            ...node,
                            id: node.id.toString()
                        }))
                    };
                });
                setData(preparedData);
            } catch (error) {
                setError(error);
            } finally {
                //setData(result);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }


    return (
        <div id="map-container">
            <div className="map-heading">

                <Dropdown   items={currentData}
                            selectedCaseStudy={selectedCaseStudy}
                            setSelectedCaseStudy={setSelectedCaseStudy}
                            setSelectedNode ={setSelectedNode}
                            setSelectedSectors={setSelectedSectors}
                            sectorCategories={sectorCategories}
                            />
            </div>

            <div>
                {selectedNode &&
                    <aside className="infoBox" style={{borderColor: sectorColors[selectedNode.sector]}}>
                        <InfoBox
                            selectedNode={selectedNode}
                            setSelectedNode={setSelectedNode}/>
                    </aside>}

                <main id="main-map-container">
                    <div>
                        {selectedNodes &&
                            <NodeLinkGraph
                                selectedNodes={selectedNodes}
                                selectedEdges={selectedEdges}
                                selectedSectors={selectedSectors}
                                selectedNode={selectedNode}
                                setSelectedNode={setSelectedNode}
                                selectedCaseStudy={selectedCaseStudy}
                                colors={sectorColors}
                                coloringAttrib={coloringAttrib}/>}
                    </div>
                </main>

                <div style={{backgroundColor: "white"}}>
                    <FilterLegend isDesktop={isDesktop}
                                  sectorColors={sectorColors}
                                  selectedSectors={selectedSectors}
                                  sectorCategories={sectorCategories}
                                  selectedCaseStudy={selectedCaseStudy}
                                  toggleCategory={toggleCategory}/>
                </div>
                {/*
                <div style={{backgroundColor: "red"}}>
                    <NetworkAnalysis isDesktop={isDesktop}
                                  sectorColors={sectorColors}
                                  selectedSectors={selectedSectors}
                                  sectorCategories={sectorCategories}
                                  toggleCategory={toggleCategory}/>
                </div>*/}
            </div>

        </div>
    );
}
import React from "react";
import { useTranslation } from 'react-i18next';


const InfoBox = ({selectedNode, setSelectedNode}) => {

    const { t } = useTranslation();

    return (
        <div className="infobox-container" onClick={() => setSelectedNode(null)}>
            {selectedNode && (
                <>
                    <p className="icon">x</p>
                    <h2>{selectedNode.label}</h2>
                    <div className="network--infobox--container">
                        <p><strong>{t('sector')}</strong></p>
                        <p>{t(`sectors.${selectedNode.sector}`)}</p>
                        {selectedNode.mainActivity && <p><strong>{t('activity')}</strong></p>}
                        {selectedNode.mainActivity && <p>{t(`activities.${selectedNode.mainActivity}`)}</p>}
                        {selectedNode.activities && <p></p>}
                        {selectedNode.activities && <p>{t(`activities.${selectedNode.activities}`)}</p>}
                        {selectedNode.region && <p><strong>{t('region')}</strong></p>}
                        {selectedNode.region && <p>{selectedNode.region}</p>}
                        {selectedNode.numberEmployees && <p><strong>{t('numEmp')}</strong></p>}
                        {selectedNode.numberEmployees && <p>{selectedNode.numberEmployees}</p>}
                        {selectedNode.years && <p><strong>{t('years')}</strong></p>}
                        {selectedNode.years && <p>{selectedNode.years}</p>}
                    </div>
                </>
            )}
        </div>
    )
}

export default InfoBox;
import React, { useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';

const Dropdown = ({items, selectedCaseStudy, setSelectedCaseStudy, setSelectedNode}) => {

    const { t } = useTranslation();

    const handleChange = (event) => {
        setSelectedCaseStudy(event.target.value);
        setSelectedNode(null);
    };

    return (
        <div className="dropdown">
            <select
                id="dropdownSelect"
                defaultChecked={selectedCaseStudy?selectedCaseStudy:t("pleaseSelect")}
                onChange={handleChange}
                className="form-select"
            >
                {!selectedCaseStudy && <option>{t("pleaseSelect")}</option>}

                {items.map(item => (
                    <option key={item.id} value={item.id}>
                    {t(item.name)}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default Dropdown;

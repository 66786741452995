import React, {useEffect, useState} from "react";
import {BACKEND_ENDPOINT} from "../App";

export function Test() {


    async function getData() {
        const response = await fetch(BACKEND_ENDPOINT+'/download_data', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });
        if (!response.ok) {
            console.error('Network response was not ok:', response.statusText);
            return; // Exit the function if there's an error
        }

        let data = await response.json();
        console.log(data); //
    }
    return (
        <>
            <h1></h1>
        </>
    )
}
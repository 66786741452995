import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';


const NodeLinkGraph = ({selectedNodes, selectedEdges, selectedSectors, setSelectedNode, selectedNode, colors, coloringAttrib}) => {
    const svgRef = useRef();

    const [filteredNodes, setFilteredNodes] = (selectedNodes);
    const [filteredEdges, setFilteredEdges] = (selectedEdges);
    const [averageDistance, setAverageDistance] = useState(0);
    const [density, setDensity] = useState(0);
    const [betweeners, setBetweeners] = useState(0);

    const { t } = useTranslation();

    const nodeSize= {
        "1-5": 5,
        "6-10": 10,
        "11-49": 15,
        "50-250": 20,
        "250+": 25,
        "": 10,
        null: 10
    };

    const valueOpacity = {
        "-2":0.2,
        "-1":0.4,
        "0":0.6,
        "1":0.8,
        "2":1,
        null: 0
    }

    useEffect(() => {


        /*if (selectedSectors && selectedSectors.size > 0){

        //console.log("In Node Link Graph", selectedNodes[1]);
        {/*if (selectedSectors && selectedSectors.size > 0){
            setFilteredNodes(nodes.filter(node => selectedSectors.has(node.sector)));
            setFilteredEdges(edges.filter(edge => filteredNodes.some(node => node.id === edge.source.id) &&
                filteredNodes.some(node => node.id === edge.target.id)))
        }*/

        /*
        const filteredNodes = selectedSectors && selectedSectors.size > 0 ?
            selectedNodes.filter(node => selectedSectors.has(node.sector))
            : selectedNodes;

        const filteredEdges = selectedSectors && selectedSectors.size > 0
            ? selectedEdges.filter(edge => filteredNodes.some(node => node.id === edge.source.id) &&
                filteredNodes.some(node => node.id === edge.target.id)) : selectedEdges;*/

        const filteredNodes = selectedSectors && selectedSectors.length > 0
            ? selectedNodes.filter(node => selectedSectors.includes(node.sector))
            : selectedNodes;

        const filteredEdges = selectedSectors && selectedSectors.length > 0
            ? selectedEdges.filter(edge =>
                filteredNodes.some(node => node.id === edge.source.id) &&
                filteredNodes.some(node => node.id === edge.target.id)
            )
            : selectedEdges;

        const width = window.innerWidth
        const height = window.innerHeight


        const zoom = d3.zoom()
            .scaleExtent([0.3, 3])
            .on("zoom", (event) => {
                g.attr("transform", event.transform);
            });

        const svg = d3.select(svgRef.current)
            //.attr('width', width)
            //.attr('height', height)
            .attr("viewBox", "0 0 " + width + " " + height )
            .attr("preserveAspectRatio", "xMidYMid meet")
            .attr("style", "max-width: 100%; height: auto;")
            .call(zoom);

        svg.selectAll("*").remove();


        const g = svg.append("g");




        const simulation = d3.forceSimulation(filteredNodes)
            .force('link', d3.forceLink().id(d => d.id).distance(100))
            .force('charge', d3.forceManyBody().strength(-50))
            .force('center', d3.forceCenter(width / 2, height / 2))
            .force("gravityX", d3.forceX(width / 2).strength(0.005))
            .force("gravityY", d3.forceY(height / 2).strength(0.005))


        calculateGraphMetrics(filteredNodes, filteredEdges);
        calculateAverageDistance(filteredNodes,filteredEdges);
        //calculateBetweennessCentrality(filteredNodes, filteredEdges);


        const link = g.append('g')
            .attr('class', 'links')
            .selectAll('line')
            .data(filteredEdges)
            .enter().append('line')
            .attr('width', 20)
            .attr('stroke', '#999')
            .attr("stroke-width", 2)
            .attr('stroke-dasharray', d => d.formal ? 0 : 7)

        //.on("click", (event, d) => handleEdgeClick(d));


        const node = g.append('g')
            .attr('class', 'nodes')
            .selectAll('circle')
            .data(filteredNodes)
            .enter().append('circle')
            .attr('r', d => nodeSize[d.numberEmployees])
            .attr('fill', d => (colors[d[coloringAttrib]]))
            //.attr('opacity', d => valueOpacity[d.networkSharesValuesSelf])
            .attr('stroke', d => (colors[d[coloringAttrib]]))
            .attr('stroke-width', d => selectedNode && selectedNode.id === d.id ? 10 : 0)
            .attr('stroke-opacity', 0.5)
            .on("click", (event, d) => handleNodeClick(d))
            .call(d3.drag()
                .on('start', dragstarted)
                .on('drag', dragged)
                .on('end', dragended));

        const labels = g.append("g")
            .attr("class", "labels")
            .selectAll("text")
            .data(filteredNodes)
            .enter().append("text")
            .attr("dy", -15) 
            .attr("text-anchor", "middle")
            .text(d => d.label); 



        /*
       const linkText = svg.selectAll(".link-text")
           .data(filteredEdges)
           .enter().append("text")
           .attr("class", "link-text")
           .attr("dy", 0)
           .attr("text-anchor", "middle")
           .attr("fill", "#3b3b3b")
           .style("display", "none")
           .text(d => t(`collaborations.${d.label}`));*/

        const linkTextGroup = g.selectAll(".link-text-group")
            .data(filteredEdges)
            .enter().append("g")
            .style("display", "none")
            .attr("class", "link-text-group");

        linkTextGroup.append("rect")
            .attr("class", "link-text-background")
            .attr("width", 300)
            .attr("height", 20)
            .attr("fill", "white")
            .style("opacity", 0.8)
            .style("transform", "translateX(-10%)");

        linkTextGroup.append("text")
            .attr("class", "link-text")
            .attr("dy", 0)
            .attr("text-anchor", "middle")
            .attr("fill", "#003781")
            .text(d => t(`collaborations.${d.label}`));

        simulation
            .nodes(filteredNodes)
            .on('tick', ticked);

        simulation.force('link')
            .links(filteredEdges);

        function ticked(){
            link
                .attr('x1', d => d.source.x)
                .attr('y1', d => d.source.y)
                .attr('x2', d => d.target.x)
                .attr('y2', d => d.target.y);

            node
                .attr('cx', d => d.x)
                .attr('cy', d => d.y);
            labels
                .attr('x', d => d.x)
                .attr('y', d => d.y);

            /*
            linkText.attr('x', d => (d.source.x + d.target.x) / 2)
                .attr('y', d => (d.source.y + d.target.y) / 2);*/
            linkTextGroup.selectAll("rect")
                .attr("x", d => (d.source.x + d.target.x) / 2 + 5)
                .attr("y", d => (d.source.y + d.target.y) / 2 - 15);

            linkTextGroup.selectAll("text")
                .attr('x', d => (d.source.x + d.target.x) / 2)
                .attr('y', d => (d.source.y + d.target.y) / 2);
        }

        simulation.alpha(1).restart();


        function handleNodeClick(nodeData) {
            console.log("clicked")
            d3.select(this).attr('stroke-width', 10)
            d3.select(this).attr('fill', "#003781")
            setSelectedNode(nodeData)
        }

        node.on("click", function (event, d) {
            node.attr('stroke-width', 0)
            const thisNode = d3.select(this);
            thisNode.attr('stroke-width', 10)
            setSelectedNode(d);
        });

        link.on("mouseover", function (event, d) {
            const label = linkTextGroup.filter(link => link === d);
            label.style("display", "block");
            d3.select(this).attr("stroke-width", 7);

            setTimeout(() => {
                label.style("display", "none");
                d3.select(this).attr("stroke-width", 2);
            }, 1000);
        });

        function dragstarted(event, d) {
            if (!event.active) simulation.alphaTarget(0.3).restart();
            d.fx = d.x;
            d.fy = d.y;
        }

        function dragged(event, d) {
            d.fx = event.x;
            d.fy = event.y;
        }

        function dragended(event, d) {
            if (!event.active) simulation.alphaTarget(0);
            d.fx = null;
            d.fy = null;
        }

        return () => {
            svg.node();
        };
    }, [selectedNodes, selectedEdges, selectedSectors]);


    function calculateBetweennessCentrality(nodes, edges) {
        const centrality = {};

        // Initialize centrality for each node
        nodes.forEach(node => {
            centrality[node.id] = 0;
        });

        // Function to perform BFS and find shortest paths
        function bfs(source) {
            const queue = [source.id];
            const paths = {};
            const sigma = {};
            const predecessors = {};
            const visited = new Set();

            visited.add(source.id);
            sigma[source.id] = 1;
            paths[source.id] = [];

            while (queue.length > 0) {
                const current = queue.shift();
                const neighbors = edges
                    .filter(edge => edge.source.id === current || edge.target.id === current)
                    .map(edge => edge.source.id === current ? edge.target.id : edge.source.id);

                neighbors.forEach(neighbor => {
                    if (!visited.has(neighbor)) {
                        visited.add(neighbor);
                        queue.push(neighbor);
                    }
                    if (!paths[neighbor]) {
                        paths[neighbor] = [];
                    }
                    paths[neighbor].push(current);

                    if (!sigma[neighbor]) {
                        sigma[neighbor] = 0;
                    }
                    sigma[neighbor] += sigma[current];
                });
            }

            return { paths, sigma };
        }

        nodes.forEach(source => {
            const { paths, sigma } = bfs(source);
            const dependency = {};

            nodes.forEach(node => {
                dependency[node.id] = 0;
            });

            for (let target in paths) {
                if (target !== source.id) {
                    paths[target].forEach(predecessor => {
                        const coeff = (sigma[predecessor] / sigma[target]) * (1 + dependency[target]);
                        dependency[predecessor] += coeff;
                    });
                    if (target !== source.id) {
                        centrality[target] += dependency[target];
                    }
                }
            }
        });
        console.log(centrality)
        setBetweeners(centrality)
    }


    const calculateGraphMetrics = (nodes, edges) => {
        const numNodes = nodes.length;
        const numEdges = edges.length;
        const densityValue = numNodes > 1 ? (2 * numEdges) / (numNodes * (numNodes - 1)) : 0;
        setDensity(densityValue);
    }
    function calculateAverageDistance(nodes, filteredEdges) {
        const distances = {};

        nodes.forEach(node => {
            distances[node.id] = {};
            nodes.forEach(otherNode => {
                distances[node.id][otherNode.id] = Infinity; // Start with infinite distance
            });
            distances[node.id][node.id] = 0; // Distance to itself is zero
        });

        const adjacencyList = {};
        filteredEdges.forEach(edge => {
            if (!adjacencyList[edge.source.id]) {
                adjacencyList[edge.source.id] = [];
            }
            if (!adjacencyList[edge.target.id]) {
                adjacencyList[edge.target.id] = [];
            }
            adjacencyList[edge.source.id].push(edge.target.id);
            adjacencyList[edge.target.id].push(edge.source.id); // For undirected graph
        });

        function bfs(startNode) {
            const queue = [startNode];
            const visited = new Set();
            visited.add(startNode);

            while (queue.length > 0) {
                const current = queue.shift();
                const neighbors = adjacencyList[current] || [];

                neighbors.forEach(neighbor => {
                    if (!visited.has(neighbor)) {
                        visited.add(neighbor);
                        queue.push(neighbor);
                        distances[startNode][neighbor] = distances[startNode][current] + 1;
                    }
                });
            }
        }

        nodes.forEach(node => {
            bfs(node.id);
        });

        let totalDistance = 0;
        let count = 0;

        nodes.forEach(node => {
            nodes.forEach(otherNode => {
                if (distances[node.id][otherNode.id] < Infinity && node.id !== otherNode.id) {
                    totalDistance += distances[node.id][otherNode.id];
                    count++;
                }
            });
        });

        const distanceValue = count > 0 ? totalDistance / count : 0; // Return average distance
        setAverageDistance(distanceValue);

    }


    return(
        <div>
            <svg ref={svgRef}></svg>
            <div className="network--metrics">
                <p>{t('density')}: {density.toFixed(2)}</p>
                <p>{t('avDistance')}: {averageDistance.toFixed(2)}</p>
            </div>

        </div>
    )
};

export default NodeLinkGraph;
